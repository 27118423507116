import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/superApi";
import { Button, Alert, Collapse } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import MergeConfigParams from "./mergeConfigParams";
import {
  JobItem,
  createJobItem,
} from "../../../../../components/Jobs/jobUtils";
import { writeJsonToS3 } from "../../../../../components/S3/S3Utils";

// --------------------------------------------------------------------------------------------------
interface ApiFileMetaData {
  path: string;
  data_type: string | null;
  drillhole_column_name: string | null;
  interval_from_column_name: string | null;
  interval_to_column_name: string | null;
  interval_depth_column_name: string | null;
  custom_metadata_column_names?: string[];
  features_strategies: { [key: string]: string } | null;
  resampling_radius: number;
  resampling_from_zero: boolean;
  intervalization_radius: number;
}

// --------------------------------------------------------------------------------------------------
interface ApiRequest {
  merging_type: "weighted" | "simple";
  project_name: string;
  environment: string;
  base_structures_info: ApiFileMetaData;
  support_structures_info: ApiFileMetaData[];
  categorical_encoding: string | null;
  numeric_encoding: string | null;
  intervalization_radius: number;
  load_litholens_reports: boolean;
  load_litholens_roqenet_reports: boolean;
  load_litholens_veinet_reports: boolean;
  register_to_litholens: boolean;
  register_to_logsapi: boolean;
  descriptive_log_name: string;
  notification_email: string | null;
  structures_to_process: [] | null;
  drillholes_to_process: [] | null;
  s3_data_save_path: string | null;
  litholens_save_path_type: "s3" | "logsAPI" | null;
}

// --------------------------------------------------------------------------------------------------
interface MergeRequestProps {
  bucketName: string;
  pathPrefix: string;
}

// --------------------------------------------------------------------------------------------------
const MergeRequest: React.FC<MergeRequestProps> = ({
  bucketName,
  pathPrefix,
}) => {
  const talim_merge = useAppSelector((state) => state.talim.talimMergeSlice);
  const dispatch = useAppDispatch();

  // --------------------------------------------------------------------------------------------------
  // Job Item & API Request

  const auth = useAppSelector((state) => state.auth);
  const projects = useAppSelector((state) => state.projects);

  // const url: string = window.location.href;
  // const company: string = url.split("/").filter(Boolean)[2];
  // const project: string = url.split("/").filter(Boolean)[3];
  // const projectName: string = company + "_" + project;
  const company = projects.selectedProject?.company || "company";
  const project = projects.selectedProject?.id || "project";
  const projectName =
    projects.selectedProject?.litholensName || "company_project";
  const type: string = "logs.merge";
  const createdBy = auth.user?.username || "superapi";

  const jobItem = createJobItem(company, project, type, createdBy);
  const [lastJobID, setLastJobID] = useState<string | null>(null);
  const env = process.env.REACT_APP_ENV || "test";

  // --------------------------------------------------------------------------------------------------
  const [apiRequest, setApiRequest] = useState<ApiRequest>({
    base_structures_info: {} as ApiFileMetaData,
    support_structures_info: [] as ApiFileMetaData[],
    project_name: projectName,
    environment: env,
    categorical_encoding: talim_merge.categorical_encoding,
    numeric_encoding: talim_merge.numeric_encoding,
    intervalization_radius: talim_merge.intervalization_radius,
    load_litholens_reports: talim_merge.load_litholens_reports,
    load_litholens_roqenet_reports: talim_merge.load_litholens_roqenet_reports,
    load_litholens_veinet_reports: talim_merge.load_litholens_veinet_reports,
    merging_type: "weighted",
    register_to_litholens: talim_merge.register_to_litholens,
    register_to_logsapi: talim_merge.register_to_logsapi,
    descriptive_log_name: "new_log",
    notification_email: talim_merge.notification_email,
    structures_to_process: null,
    drillholes_to_process: null,
    s3_data_save_path: talim_merge.s3_data_save_name,
    litholens_save_path_type: "s3",
  });

  // --------------------------------------------------------------------------------------------------
  // Send API Request
  const [runServiceMutation, runServiceMutationResult] =
    useRunServiceMutation();

  const handleSubmit = async (requestData: RunServiceRequest) => {
    runServiceMutation(requestData);
    const fixedItem = requestData.job_item as JobItem;
    setLastJobID(fixedItem.id);
    const pendingMessage = { status: "requested" };
    writeJsonToS3(
      bucketName,
      pathPrefix + "/responses/merge/" + jobItem.id + ".json",
      pendingMessage
    );
  };

  const log = (type: any) => console.log.bind(console, type);

  // --------------------------------------------------------------------------------------------------
  const [open, setOpen] = useState(false);
  const handleAlertClose = (event: SyntheticEvent) => {
    setOpen(false);
  };

  useEffect(() => {
    var newBaseStructuresInfo = {} as ApiFileMetaData;
    var newSupportStructuresInfo = [] as ApiFileMetaData[];
    Object.values(talim_merge.filesInfo).forEach((element) => {
      if (element.fileKey === talim_merge.selectedReferenceFile) {
        newBaseStructuresInfo = {
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
          custom_metadata_column_names:
            element.metaData?.customMetaData || ([] as string[]),
          features_strategies: element.columnStrategies,
          resampling_radius: element.resamplingRadius,
          resampling_from_zero: element.resamplingFromZero,
          intervalization_radius: element.intervalizationRadius,
        };
      } else if (talim_merge.selectedSupportFiles.includes(element.fileKey)) {
        newSupportStructuresInfo.push({
          path: "s3://" + bucketName + "/" + element.fileKey,
          data_type: element.metaData.selectedType,
          drillhole_column_name: element.metaData.drillhole,
          interval_from_column_name: element.metaData.begin,
          interval_to_column_name: element.metaData.end,
          interval_depth_column_name: element.metaData.pointDepth,
          custom_metadata_column_names:
            element.metaData?.customMetaData || ([] as string[]),
          features_strategies: Object.fromEntries(
            Object.entries(element.columnStrategies).filter(
              ([key, value]) => value !== "exclude" && typeof value == "string"
            )
          ) as { [key: string]: string },
          resampling_radius: element.resamplingRadius,
          resampling_from_zero: element.resamplingFromZero,
          intervalization_radius: element.intervalizationRadius,
        });
      }

      console.log(talim_merge.s3_data_save_name);
    });
    var modifiedLogDescripitveName = "new_log";
    if (typeof talim_merge.descriptive_log_name === "string") {
      modifiedLogDescripitveName = talim_merge.descriptive_log_name;
    }
    setApiRequest({
      ...apiRequest,
      base_structures_info: newBaseStructuresInfo,
      support_structures_info: newSupportStructuresInfo,
      project_name:
        projects.selectedProject?.litholensName || "company_project",
      intervalization_radius: talim_merge.intervalization_radius,
      load_litholens_reports: talim_merge.load_litholens_reports,
      load_litholens_roqenet_reports:
        talim_merge.load_litholens_roqenet_reports,
      load_litholens_veinet_reports: talim_merge.load_litholens_veinet_reports,
      register_to_litholens: talim_merge.register_to_litholens,
      register_to_logsapi: talim_merge.register_to_logsapi,
      notification_email: talim_merge.notification_email,
      descriptive_log_name: talim_merge.descriptive_log_name,
      s3_data_save_path: talim_merge.s3_data_save_name
        ? "s3://" +
          bucketName +
          "/" +
          pathPrefix +
          "/data/" +
          talim_merge.s3_data_save_name +
          ".csv"
        : null,
    });
  }, [talim_merge, projects.selectedProject]);

  useEffect(() => {
    // if (runServiceMutationResult?.isSuccess) {
    //   alert("API request has been sent successfully!");
    // } else {
    //   if (runServiceMutationResult?.isError) {
    //     alert("API request has been encountered an error!");
    //   }
    // }
    setOpen(true);
  }, [runServiceMutationResult]);

  // --------------------------------------------------------------------------------------------------
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      {/* <h2>Selected Reference File:</h2>
      <li key={selectedReferenceFile}>
        {selectedReferenceFile}{" "}
        <pre>
          {filesInfo[selectedReferenceFile] &&
            JSON.stringify(
              {
                key: filesInfo[selectedReferenceFile].fileKey,
                metaData: filesInfo[selectedReferenceFile].metaData,
                columnStrategies:
                  filesInfo[selectedReferenceFile].columnStrategies,
              },
              null,
              2
            )}
        </pre>
      </li>
      <h2>Selected Support Files:</h2>
      <ul>
        {selectedSupportFiles.map((key) => (
          <li key={key}>
            {key}{" "}
            <pre>
              {filesInfo[key] &&
                JSON.stringify(
                  {
                    key: filesInfo[key].fileKey,
                    metaData: filesInfo[key].metaData,
                    columnStrategies: filesInfo[key].columnStrategies,
                  },
                  null,
                  2
                )}
            </pre>
          </li>
        ))}
      </ul> */}

      <MergeConfigParams />
      <Button
        variant="contained"
        onClick={(e) =>
          handleSubmit({
            service: "ll_run_talim_merge",
            request_body: apiRequest,
            job_item: jobItem,
            environment: env,
          } as RunServiceRequest)
        }
        disabled={runServiceMutationResult.isLoading}
        onError={log("errors")}
      >
        Send Request
      </Button>
      <Collapse in={open}>
        {(runServiceMutationResult?.isSuccess ||
          runServiceMutationResult?.isError) && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity={runServiceMutationResult?.isSuccess ? "success" : "error"}
            onClose={handleAlertClose}
          >
            {runServiceMutationResult?.isSuccess
              ? "Request sent successfully! Your request ID is " + lastJobID
              : "Request Sending has been ecnountered an error!"}
          </Alert>
        )}
      </Collapse>
      {/* <pre>{JSON.stringify(apiRequest, null, 2)}</pre> */}
    </div>
  );
};

export default MergeRequest;
