interface configItem {
  coreApiBaseUrl: string;
  cognitoUrl: string;
  cognitoClientId: string;
  superApiUrl: string;
  kuberApiUrl: string;
  kuberApiToken: string;
  projectsApiUrl: string;
  filesApiUrl: string;
  projects_s3_bucket: string;
  talim_s3_bucket: string;
  sms_s3_bucket: string;
  tsm_s3_bucket: string;
  xrd_s3_bucket: string;
  environment_s3_bucket: string;
  litholens_s3_bucket: string;
  awsConfig: {
    Auth: {
      mandatorySignIn: boolean;
      region: string;
      userPoolId: string;
      userPoolWebClientId: string;
      identityPoolId: string;
    };
  };
}

// -------------------------------------------------------------------------------------
export const ENVIRONMENT = process.env.REACT_APP_ENV || "test";
export const DRILLHOLES_FOLDER_PATH = "!_LITHOLENS-DATA/01_Data/01_CorePhotos";
export const CLUSTERING_MODELS_FOLDER_PATH =
  "!_LITHOLENS-DATA/03_MachineLearning/01_Models/02_Clusters/01_CorePhotos";

export const CLEANING_MODELS_FOLDER_PATH =
  "!_LITHOLENS-DATA/03_MachineLearning/01_Models/02_Clusters/01_CorePhotos";

export const SURVEY_COLLAR_FOLDER_PATH = "!_LITHOLENS-DATA/survey_collar";
// -------------------------------------------------------------------------------------
const COGNITO_URL = "https://cognito-idp.us-west-1.amazonaws.com/";

// -------------------------------------------------------------------------------------
export const config: { [env: string]: configItem } = {
  test: {
    coreApiBaseUrl: "",
    cognitoUrl: COGNITO_URL,
    cognitoClientId: "5le4mn956di7l8gjqmfd07s165",
    kuberApiToken: "",
    superApiUrl:
      "https://yqnagtre0g.execute-api.us-east-1.amazonaws.com/superapi-test",
    // kuberApiUrl: "https://kuberapi.test.backend.api.alsgeoanalytics.com",
    kuberApiUrl: "https://kuberapi.backend.api.goldspot.ca",
    projectsApiUrl: "https://projects.test.api.goldspot.ca",
    filesApiUrl: "https://files.test.api.goldspot.ca",
    talim_s3_bucket: "superapi-dashboard-test-temp1",
    sms_s3_bucket: "superapi-dashboard-test-temp1",
    tsm_s3_bucket: "superapi-dashboard-test-temp1",
    xrd_s3_bucket: "superapi-dashboard-test-temp1",
    environment_s3_bucket: "superapi-dashboard-test-temp1",
    litholens_s3_bucket: "superapi-dashboard-test-temp1",
    projects_s3_bucket: "gsp-api-files-test",
    awsConfig: {
      Auth: {
        mandatorySignIn: true,
        region: "us-west-1",
        userPoolId: "us-west-1_YpkY0J7xQ",
        userPoolWebClientId: "5le4mn956di7l8gjqmfd07s165",
        identityPoolId: "us-west-1:b521f7cf-bcc4-4bf0-8dfd-18b426782b01",
      },
    },
  },
  prod: {
    coreApiBaseUrl: "",
    cognitoUrl: COGNITO_URL,
    cognitoClientId: "3j64kg2t07cj9cs3p4j34btbs8",
    superApiUrl:
      "https://9fqqc7b3b0.execute-api.us-east-1.amazonaws.com/superapi-prod",
    kuberApiUrl: "https://kuberapi.backend.api.goldspot.ca",
    kuberApiToken: "",
    projectsApiUrl: "https://projects.api.goldspot.ca",
    filesApiUrl: "https://files.api.goldspot.ca",
    projects_s3_bucket: "gsp-api-files-prod",
    talim_s3_bucket: "superapi-dashboard-prod",
    sms_s3_bucket: "superapi-dashboard-prod",
    tsm_s3_bucket: "superapi-dashboard-prod",
    xrd_s3_bucket: "superapi-dashboard-prod",
    environment_s3_bucket: "superapi-dashboard-prod",
    litholens_s3_bucket: "superapi-dashboard-prod",
    awsConfig: {
      Auth: {
        mandatorySignIn: true,
        region: "us-west-1",
        userPoolId: "us-west-1_MbhJH1UIV",
        userPoolWebClientId: "3j64kg2t07cj9cs3p4j34btbs8",
        identityPoolId: "us-west-1:b521f7cf-bcc4-4bf0-8dfd-18b426782b01",
      },
    },
  },
};
// -------------------------------------------------------------------------------------
// EXAMPLE USAGE:
// const env = process.env.REACT_APP_ENV || "test";
const projects_s3_bucket = config[ENVIRONMENT].projects_s3_bucket;

export default config;
