// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
  FormControl,
  Autocomplete,
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Alert,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { readJsonFromS3 } from "../../../../../components/S3/S3Utils";
import Plot from "react-plotly.js";
import { Datum } from "plotly.js";

interface QQPlotsProps {
  [key: string]: any;
}

interface ApiResponse {
  merged_report?: string | null;
  out_location?: string | null;
  out_log_id?: string | null;
  out_generic_model_id?: number | null;
  warnings?: string[] | null;
  error?: string | null;
  status?: string | null;
  qq_plots?: { [key: string]: QQPlotsProps };
}

interface CleaningResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const CleaningResponseViewer: React.FC<CleaningResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        setFiles(fetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          setIsLoading(true);
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedIndex, files]);

  const handleDownload = async (key: string) => {
    try {
      const url = await getDownloadUrl(bucketName, key);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  /// QQ Plots

  const [qqPlotColumn, setQQPlotColumn] = useState<string | null>(null);

  const handleQQSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;
    setQQPlotColumn(newValue);
  };

  interface QQPlotProps {
    qq_plots?: {
      [key: string]: { [dataType: string]: { [key: string]: number[] } };
    };
    key_name: string | null;
  }

  const QQPlot: React.FC<QQPlotProps> = ({ qq_plots, key_name }) => {
    if (!qq_plots || !key_name) {
      return <Typography variant="h6">QQ plot not found</Typography>;
    }

    const data = qq_plots[key_name];

    const originalData = data.original_data;
    const imputedData = data.imputed_data;

    return (
      <div>
        <Plot
          data={[
            {
              x: originalData.x_axis,
              y: originalData.y_axis,
              mode: "markers",
              name: "Original Data",
              type: "scatter",
            },
            {
              x: imputedData.x_axis,
              y: imputedData.y_axis,
              mode: "markers",
              name: "Imputed Data",
              type: "scatter",
            },
          ]}
          layout={{
            title: `QQ Plot for ${key_name}`,
            xaxis: {
              title: "Theoretical Quantiles",
              scaleanchor: "y",
              scaleratio: 1,
            },
            yaxis: {
              title: "Sample Quantiles",
              type: "log",
              showgrid: true,
              gridwidth: 1,
              gridcolor: "#ccc",
              dtick: 0.301,
              tickformat: ".3f",
              automargin: true,
            },
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <List
            component="nav"
            aria-label="main mailbox foldersfinished-jobs"
            title="List of Inference Jobs"
          >
            {files.map((file, index) => (
              <ListItemButton
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemText
                  primary={file.Key.replace(
                    pathPrefix + "/responses/" + serviceName + "/",
                    ""
                  )}
                />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper}>
            <TableBody>
              <TableRow>
                <TableCell>Out S3 File Name</TableCell>
                <TableCell>
                  {apiResponse.out_location?.substring(
                    apiResponse.out_location?.lastIndexOf("/") + 1
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Warnings</TableCell>
                <TableCell>
                  {apiResponse.warnings
                    ? apiResponse.warnings.length > 0
                      ? apiResponse.warnings
                      : "N/A"
                    : "N/A"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Error</TableCell>
                <TableCell>
                  {apiResponse.error ? apiResponse.error : "N/A"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Download Cleaned File</TableCell>
                <TableCell>
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() =>
                        handleDownload(
                          apiResponse.out_location
                            ? apiResponse.out_location.replace(
                                "s3://" + bucketName + "/",
                                ""
                              )
                            : ""
                        )
                      }
                      disabled={
                        apiResponse.out_location === null ||
                        apiResponse.out_location === undefined
                      }
                      aria-label="download"
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>
                  <Chip
                    label={
                      apiResponse.status === "requested"
                        ? "requested"
                        : apiResponse.status === "failed"
                        ? "failed"
                        : "done"
                    }
                    color={
                      apiResponse.status === "requested"
                        ? "warning"
                        : apiResponse.status === "failed"
                        ? "error"
                        : "success"
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </TableContainer>
        </Grid>
      </Grid>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={handleChange}
        aria-label="file tabs"
        sx={{
          backgroundColor: "white", // Light teal background for the whole tabs bar
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
          "& .MuiTabs-flexContainer": {
            gap: "10px", // Adds space between each tab/button
          },
        }}
      >
        <Tab key="qq" label="QQ Plots" id="qq" aria-controls={`tabpanel-qq`} />
      </Tabs>
      {isLoading ? (
        <div style={{ textAlign: "center", margin: "15px" }}>
          <CircularProgress />
        </div>
      ) : apiResponse?.status === "requested" ? (
        <div>
          <Alert severity="warning">Job in progress</Alert>
        </div>
      ) : apiResponse?.status === "failed" ? (
        <div>
          <Alert severity="error">Job failed!</Alert>
        </div>
      ) : (
        <div>
          <div
            hidden={activeTab !== 0}
            id="histogram"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <FormControl fullWidth margin="normal">
              <Autocomplete
                options={
                  apiResponse?.qq_plots
                    ? Object.keys(apiResponse?.qq_plots)
                    : []
                }
                getOptionLabel={(option) => option}
                value={qqPlotColumn}
                onChange={handleQQSelection}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="QQ Plots Column"
                    variant="outlined"
                  />
                )}
                fullWidth
              />
            </FormControl>
            <QQPlot qq_plots={apiResponse.qq_plots} key_name={qqPlotColumn} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CleaningResponseViewer;
