import React, { useState, useEffect, ChangeEvent } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Switch,
  Button,
  Grid,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setFilesInfo } from "./talimMergeSlice";

interface TableDataDropdownProps {
  fileKey: string;
  isReference: boolean;
}

interface Options {
  categorical: string[];
  numeric: string[];
}

const options: Options = {
  categorical: ["multihot", "weighted", "nearest", "majority", "exclude"],
  numeric: ["mean", "sum", "meanstd", "exclude"],
};

type ColumnType = "categorical" | "numeric";

interface ColumnTypes {
  [key: string]: ColumnType;
}

interface Selections {
  [key: string]: string;
}

// ----------------------------------------------------------------------------------------------
const determineType = (columnData: any[]): "categorical" | "numeric" => {
  let numericCount = 0;
  for (const cell of columnData) {
    if (cell != null) {
      const cleanedCell = cell.toString();
      if (
        !isNaN(parseFloat(cleanedCell)) &&
        isFinite(parseFloat(cleanedCell))
      ) {
        numericCount++;
      }
    }
  }
  // Consider a column numeric if more than 80% of its entries are numeric
  return numericCount / columnData.length > 0.8 ? "numeric" : "categorical";
};

// ----------------------------------------------------------------------------------------------
export const getDefaultOptionsForColumns = (tableData: any[]): Selections => {
  if (!Array.isArray(tableData) || tableData.length === 0) {
    return {};
  }

  // Extract column names from the first row's keys
  const columnNames = Object.keys(tableData[0]);

  const selections: Selections = {};

  // Iterate over each column name to determine its type
  columnNames.forEach((columnName) => {
    const columnData = tableData.map((row) => row[columnName]);
    const type = determineType(columnData);
    // Select the first option based on the type
    selections[columnName] = options[type][0];
  });

  return selections;
};

// ----------------------------------------------------------------------------------------------
const ColumnStrategies: React.FC<TableDataDropdownProps> = ({
  fileKey,
  isReference,
}) => {
  const talim_merge = useAppSelector((state) => state.talim.talimMergeSlice);
  const dispatch = useAppDispatch();
  const [columnTypes, setColumnTypes] = useState<
    Record<string, "categorical" | "numeric">
  >({});
  const [selections, setSelections] = useState<Record<string, string>>(
    talim_merge.filesInfo[fileKey]?.columnStrategies || {}
  );

  const [defaultCategoricalEncoding, setDefaultCategoricalEncoding] = useState(
    options.categorical[0]
  );

  const [defaultNumericEncoding, setDefaultNumericEncoding] = useState(
    options.numeric[0]
  );

  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [dialogBoxKey, setDialogBoxKey] = useState<string | null>(null);

  const tableData = talim_merge.filesInfo[fileKey].tableData.data;

  const metaData = talim_merge.filesInfo[fileKey].metaData;

  useEffect(() => {
    // console.log(selections);

    if (tableData.length > 0) {
      const columnNames = Object.keys(tableData[0]);
      const newColumnTypes = columnNames.map((name) => {
        const type = determineType(tableData.map((row) => row[name]));
        return { name, type };
      });

      setColumnTypes(
        newColumnTypes.reduce(
          (acc, { name, type }) => ({
            ...acc,
            [name]: type,
          }),
          {}
        )
      );
    }
  }, [tableData]);

  const handleSelectionChange =
    (columnName: string) => (event: SelectChangeEvent) => {
      const newSelections = {
        ...selections,
        [columnName]: event.target.value,
      };
      setSelections(newSelections);
      dispatch(
        setFilesInfo({
          key: fileKey,
          info: {
            fileKey: talim_merge.filesInfo[fileKey].fileKey,
            metaData: talim_merge.filesInfo[fileKey].metaData,
            tableData: talim_merge.filesInfo[fileKey].tableData,
            columnStrategies: newSelections,
            resamplingRadius: talim_merge.filesInfo[fileKey].resamplingRadius,
            resamplingFromZero:
              talim_merge.filesInfo[fileKey].resamplingFromZero,
            intervalizationRadius:
              talim_merge.filesInfo[fileKey].intervalizationRadius,
          },
        })
      );
    };

  const handleTypeChange =
    (columnName: string) => (event: SelectChangeEvent) => {
      if (
        event.target.value === "categorical" ||
        event.target.value === "numeric"
      ) {
        const newColumnTypes = {
          ...columnTypes,
          [columnName]: event.target.value,
        } as Record<string, "categorical" | "numeric">;
        setColumnTypes(newColumnTypes);

        const newSelections = {
          ...selections,
          [columnName]:
            event.target.value === "categorical"
              ? defaultCategoricalEncoding
              : defaultNumericEncoding,
        };
        setSelections(newSelections);
        dispatch(
          setFilesInfo({
            key: fileKey,
            info: {
              fileKey: talim_merge.filesInfo[fileKey].fileKey,
              metaData: talim_merge.filesInfo[fileKey].metaData,
              tableData: talim_merge.filesInfo[fileKey].tableData,
              columnStrategies: newSelections,
              resamplingRadius: talim_merge.filesInfo[fileKey].resamplingRadius,
              resamplingFromZero:
                talim_merge.filesInfo[fileKey].resamplingFromZero,
              intervalizationRadius:
                talim_merge.filesInfo[fileKey].intervalizationRadius,
            },
          })
        );
      }
    };

  const handleDefaultCategoricalEncodingChange = (event: SelectChangeEvent) => {
    setDefaultCategoricalEncoding(event.target.value);
    var newSelections = selections;
    Object.entries(columnTypes).map(([columnName, type]) => {
      if (type === "categorical") {
        newSelections = {
          ...newSelections,
          [columnName]: event.target.value,
        };
      }
    });
    setSelections(newSelections);
    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_merge.filesInfo[fileKey].fileKey,
          metaData: talim_merge.filesInfo[fileKey].metaData,
          tableData: talim_merge.filesInfo[fileKey].tableData,
          columnStrategies: newSelections,
          resamplingRadius: talim_merge.filesInfo[fileKey].resamplingRadius,
          resamplingFromZero: talim_merge.filesInfo[fileKey].resamplingFromZero,
          intervalizationRadius:
            talim_merge.filesInfo[fileKey].intervalizationRadius,
        },
      })
    );
  };

  const handleDefaultNumericEncodingChange = (event: SelectChangeEvent) => {
    setDefaultNumericEncoding(event.target.value);
    var newSelections = selections;
    Object.entries(columnTypes).map(([columnName, type]) => {
      if (type === "numeric") {
        newSelections = {
          ...newSelections,
          [columnName]: event.target.value,
        };
      }
    });
    setSelections(newSelections);
    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_merge.filesInfo[fileKey].fileKey,
          metaData: talim_merge.filesInfo[fileKey].metaData,
          tableData: talim_merge.filesInfo[fileKey].tableData,
          columnStrategies: newSelections,
          resamplingRadius: talim_merge.filesInfo[fileKey].resamplingRadius,
          resamplingFromZero: talim_merge.filesInfo[fileKey].resamplingFromZero,
          intervalizationRadius:
            talim_merge.filesInfo[fileKey].intervalizationRadius,
        },
      })
    );
    console.log(talim_merge.filesInfo);
  };

  const handleResamplingFromZeroChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newResamplingFromZero = event.target.checked;

    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_merge.filesInfo[fileKey].fileKey,
          metaData: talim_merge.filesInfo[fileKey].metaData,
          tableData: talim_merge.filesInfo[fileKey].tableData,
          columnStrategies: selections,
          resamplingRadius: talim_merge.filesInfo[fileKey].resamplingRadius,
          resamplingFromZero: newResamplingFromZero,
          intervalizationRadius:
            talim_merge.filesInfo[fileKey].intervalizationRadius,
        },
      })
    );
  };

  const handleResamplingRadiusChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newResamplingRadius = Number(event.target.value);

    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_merge.filesInfo[fileKey].fileKey,
          metaData: talim_merge.filesInfo[fileKey].metaData,
          tableData: talim_merge.filesInfo[fileKey].tableData,
          columnStrategies: selections,
          resamplingRadius: newResamplingRadius,
          resamplingFromZero: talim_merge.filesInfo[fileKey].resamplingFromZero,
          intervalizationRadius:
            talim_merge.filesInfo[fileKey].intervalizationRadius,
        },
      })
    );
  };

  const handleIntervalizationRadiusChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newIntervalizatioRadius = Number(event.target.value);

    dispatch(
      setFilesInfo({
        key: fileKey,
        info: {
          fileKey: talim_merge.filesInfo[fileKey].fileKey,
          metaData: talim_merge.filesInfo[fileKey].metaData,
          tableData: talim_merge.filesInfo[fileKey].tableData,
          columnStrategies: selections,
          resamplingRadius: talim_merge.filesInfo[fileKey].resamplingRadius,
          resamplingFromZero: talim_merge.filesInfo[fileKey].resamplingFromZero,
          intervalizationRadius: newIntervalizatioRadius,
        },
      })
    );
  };

  const handleClickOpen = (key: string) => {
    setDialogBoxKey(key);
    setOpenDialogBox(true);
  };

  const handleClose = () => {
    setDialogBoxKey(null);
    setOpenDialogBox(false);
  };

  const getDialogText = (key: string) => {
    switch (key) {
      case "categorical":
        return (
          <span>
            <strong>Weighted Encoding</strong> For each pair of feature and
            category, a column with continuous values is reported, showing the
            correlation fraction between the intervals reporting the category
            and base intervals.
            <br />
            <br />
            <strong>Nearest Encoding</strong> For each feature and in each
            interval, the category reported in the nearest region to the
            midpoint of the interval.
            <br />
            <br />
            <strong>Majority Encoding</strong> For each feature and in each
            interval, the category reported in the most extent of the interval
            is reported.
            <br />
            <br />
            <strong>Multihot Encoding</strong> For each pair of feature and
            category, a column with binary values is reported, showing 1 if the
            feature exists in the base interval, and 0 otherwise.
            <br />
            <br />
          </span>
        );
      case "numeric":
        return (
          <span>
            <strong>Mean Encoding</strong> For each feature and in each
            interval, the weighted average of values is reported, using the
            correlation between support and reference intervals as value
            weights.
            <br />
            <br />
            <strong>Sum Encoding</strong> For each feature and in each interval,
            the weighted sum of values is reported, using the correlation
            between support and reference intervals as value weights.
            <br />
            <br />
            <strong>Meanstd Encoding</strong> For each feature and in each
            interval, the weighted average of values is reported alongside with
            the measurement standard deviations, using the correlation between
            support and reference intervals as value weights.
            <br />
            <br />
          </span>
        );

      default:
        return "No info available!";
    }
  };

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              {/* <InputLabel>Resampling Radius</InputLabel> */}
              <TextField
                label="Resampling Radius"
                type="number"
                value={talim_merge.filesInfo[fileKey].resamplingRadius}
                onChange={handleResamplingRadiusChange}
                inputProps={{ step: 0.01 }}
                // InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                control={
                  <Switch
                    checked={talim_merge.filesInfo[fileKey].resamplingFromZero}
                    onChange={handleResamplingFromZeroChange}
                    disabled={
                      talim_merge.filesInfo[fileKey].resamplingRadius == 0
                    }
                  />
                }
                label="Resampling From Zero"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth margin="normal">
              {/* <InputLabel>Intervalization Radius</InputLabel> */}
              <TextField
                label="Intervalization Radius"
                type="number"
                value={talim_merge.filesInfo[fileKey].intervalizationRadius}
                onChange={handleIntervalizationRadiusChange}
                inputProps={{ step: 0.01 }}
                disabled={
                  talim_merge.filesInfo[fileKey].metaData.selectedType ==
                  "interval"
                }
                // InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      {!isReference && (
        <div style={{ overflowX: "auto" }}>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Default Categorical Encoding</InputLabel>
                <Select
                  value={defaultCategoricalEncoding}
                  label={defaultCategoricalEncoding}
                  onChange={handleDefaultCategoricalEncodingChange}
                >
                  {options.categorical.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Default Numeric Encoding</InputLabel>
                <Select
                  value={defaultNumericEncoding}
                  label={defaultNumericEncoding}
                  onChange={handleDefaultNumericEncodingChange}
                >
                  {options.numeric.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} wrap="nowrap">
            {Object.entries(columnTypes).map(
              ([columnName, type]) =>
                !Object.values(metaData).includes(columnName) && (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    key={columnName}
                    style={{ minWidth: 160 }}
                  >
                    <FormControl fullWidth margin="normal">
                      <InputLabel>{columnName}</InputLabel>
                      <Select
                        value={selections[columnName]}
                        label={columnName}
                        onChange={handleSelectionChange(columnName)}
                      >
                        {options[type].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
            )}
          </Grid>
          <Grid container spacing={2} wrap="nowrap">
            {Object.entries(columnTypes).map(
              ([columnName, type]) =>
                !Object.values(metaData).includes(columnName) && (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    key={columnName}
                    style={{ minWidth: 160 }}
                  >
                    <FormControl fullWidth margin="normal">
                      <InputLabel>{columnName}</InputLabel>
                      <Select
                        value={type}
                        label={type}
                        onChange={handleTypeChange(columnName)}
                      >
                        <MenuItem key="categorical" value="categorical">
                          Categorical
                        </MenuItem>
                        <MenuItem key="numeric" value="numeric">
                          Numeric
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )
            )}
          </Grid>
          <Grid container spacing={2} wrap="nowrap">
            {Object.entries(columnTypes).map(
              ([columnName, type]) =>
                !Object.values(metaData).includes(columnName) && (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    key={columnName}
                    style={{ minWidth: 160 }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<HelpIcon />}
                      onClick={() => handleClickOpen(columnTypes[columnName])}
                    ></Button>
                  </Grid>
                )
            )}
          </Grid>
          <Dialog open={openDialogBox} onClick={handleClose}>
            <DialogContent>
              <DialogContentText>
                {getDialogText(dialogBoxKey || "")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={handleClose}
              ></Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default ColumnStrategies;
