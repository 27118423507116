// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import { fetchFiles, deleteFile, getDownloadUrl, S3File } from "./S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { Auth } from "@aws-amplify/auth";
import AWS from "aws-sdk";

interface S3FileViewerProps {
  bucketName: string;
  pathPrefix: string;
  refresh: any;
  allowedExtensions: string[];
}

type AWSCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
};

const S3FileViewer: React.FC<S3FileViewerProps> = ({
  bucketName,
  pathPrefix,
  refresh,
  allowedExtensions,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const loadFiles = async () => {
    try {
      const fetchedFiles = await fetchFiles(
        bucketName,
        pathPrefix,
        allowedExtensions
      );
      setFiles(fetchedFiles);
      console.log(pathPrefix);
      console.log(fetchedFiles);
    } catch (error) {
      console.error("Failed to fetch files:", error);
    }
  };

  useEffect(() => {
    loadFiles();
    console.log("Click");
  }, [refresh, bucketName, pathPrefix]);

  useEffect(() => {
    const reloadCredentials = async () => {
      try {
        const credentials = (await Auth.currentCredentials()) as AWSCredentials;
        localStorage.setItem("AWSCreds", JSON.stringify(credentials));
        AWS.config.update({
          credentials: credentials,
        });
      } catch (error) {
        console.error("Error fetching credentials:", error);
      }
    };

    reloadCredentials();
  }, []);

  const handleDownload = async (key: string) => {
    try {
      const url = await getDownloadUrl(bucketName, key);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = files.map((n) => n.Key);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (key: string) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleDeleteSelected = () => {
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      for (let key of selected) {
        await deleteFile(bucketName, key);
      }
      setFiles(files.filter((file) => !selected.includes(file.Key)));
      setSelected([]);
    } catch (error) {
      console.error("Error during deletion:", error);
      alert("Deletion Prohibited!");
    }
    setOpenDialog(false);
  };

  const isSelected = (key: string) => selected.indexOf(key) !== -1;

  return (
    <div>
      <p style={{ textAlign: "center" }}>Uploaded Files:</p>
      <Toolbar>
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteSelected} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: "0px 10px" }}>
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < files.length
                    }
                    checked={
                      files.length > 0 && selected.length === files.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell sx={{ padding: "0px 10px" }}>Name</TableCell>
                <TableCell sx={{ padding: "0px 10px" }} align="right">
                  Size (MB)
                </TableCell>
                <TableCell sx={{ padding: "0px 10px" }}>Type</TableCell>
                <TableCell sx={{ padding: "0px 10px" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file) => (
                <TableRow
                  key={file.Key}
                  hover
                  onClick={() => handleClick(file.Key)}
                  role="checkbox"
                  aria-checked={isSelected(file.Key)}
                  selected={isSelected(file.Key)}
                >
                  <TableCell sx={{ padding: "0px 10px" }}>
                    <Checkbox
                      checked={isSelected(file.Key)}
                      inputProps={{ "aria-labelledby": file.Key }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ padding: "0px 10px" }}
                    component="th"
                    scope="row"
                  >
                    {file.Key.replace(pathPrefix + "/", "")}
                  </TableCell>
                  <TableCell sx={{ padding: "0px 10px" }} align="right">
                    {file.Size}
                  </TableCell>
                  <TableCell sx={{ padding: "0px 10px" }}>
                    {file.Type}
                  </TableCell>
                  <TableCell sx={{ padding: "0px 10px" }}>
                    <Tooltip title="Download">
                      <IconButton
                        onClick={() => handleDownload(file.Key)}
                        aria-label="download"
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected files? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default S3FileViewer;
