// S3FileViewer.tsx
import React, { useEffect, useState } from "react";
import {
  fetchFiles,
  deleteFile,
  getDownloadUrl,
  S3File,
} from "../../../../../components/S3/S3Utils";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  TextField,
  Grid,
  Alert,
  Checkbox,
  InputLabel,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  MenuItem,
  OutlinedInput,
  Button,
  Box,
  Tabs,
  Tab,
  List,
  CircularProgress,
  ListItemButton,
  FormControl,
  Autocomplete,
  Select,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import { ReactNode } from "react";
import { readJsonFromS3 } from "../../../../../components/S3/S3Utils";
import Plot from "react-plotly.js";
import { Data, Datum } from "plotly.js";

import {
  RunServiceRequest,
  useRunServiceMutation,
} from "../../../../api/endpoints/kuberApi";
import { bool } from "aws-sdk/clients/signer";

interface HistogramData {
  bin_edges: number[];
  hist: number[];
  cum_hist: number[];
}

interface StatisticalAnalysisProps {
  // mean: string;
  // median: string;
  // minimum: string;
  // maximum: string;
  // std: string;
  // percentile_50: string;
  // count: string;
  // missing: string;
  // missing_percent: string;
  [key: string]: any;
}

interface FeatureFamilies {
  hierarchies: number[][];
  indexes: string[];
}

interface SparsitySubData {
  [key: string]: [number, number][];
}

interface SparsityData {
  [key: string]: string | SparsitySubData;
}

interface InputPreviewProps {
  inputs_dict: { [key: string]: any };
}

interface ApiResponse {
  statistical_analysis?: { [key: string]: StatisticalAnalysisProps };
  histogram?: { [key: string]: HistogramData };
  feature_correlation?: { [key: string]: { [key: string]: number } };
  feature_families?: FeatureFamilies;
  scatter_plots?: { [key: string]: number[] };
  status?: string | null;
  error?: string | null;
  inputs_dict?: InputPreviewProps;
}

interface EDAResponseViewerProps {
  refresh: any;
  bucketName: string;
  pathPrefix: string;
  serviceName: string;
}

const EDAResponseViewer: React.FC<EDAResponseViewerProps> = ({
  refresh,
  bucketName,
  pathPrefix,
  serviceName,
}) => {
  const [files, setFiles] = useState<S3File[]>([]);
  const [openDialog, setOpenDialog] = useState(false);

  const responseKeys = [
    "statistical_analysis",
    "histogram",
    "feature_correlation",
    "feature_families",
    "error",
  ];

  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = React.useState<number>(0);

  const env = process.env.REACT_APP_ENV || "test";

  const [runKuberServiceMutation, runKuberServiceMutationResult] =
    useRunServiceMutation();

  const log = (type: any) => console.log.bind(console, type);

  const handleKuberSubmit = async (requestData: RunServiceRequest) => {
    runKuberServiceMutation(requestData);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const fetchedFiles = await fetchFiles(
          (bucketName = bucketName),
          (pathPrefix = pathPrefix + "/responses/" + serviceName),
          ["json"]
        );
        const filteredFetchedFiles = fetchedFiles
          ? fetchedFiles.filter(
              (file) => file.Key.lastIndexOf("/") < file.Key.lastIndexOf("job.")
            )
          : fetchedFiles;
        setFiles(filteredFetchedFiles);
      } catch (error) {
        console.error("Failed to fetch files:", error);
      }
    };
    loadFiles();
  }, [refresh, bucketName, pathPrefix]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const [apiResponse, setApiResponse] = useState<ApiResponse>(
    files.length > 0
      ? (readJsonFromS3(bucketName, files[selectedIndex].Key) as ApiResponse)
      : ({} as ApiResponse)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedIndex >= 0 && selectedIndex < files.length) {
          setIsLoading(true);
          const response = await readJsonFromS3(
            bucketName,
            files[selectedIndex].Key
          );
          setApiResponse(response as ApiResponse);
          if (response?.histogram) {
            setSelectedHistogramColumn(Object.keys(response?.histogram)[0]);
          }
          if (response?.scatter_plots) {
            setXAxisName(Object.keys(response?.scatter_plots)[0]);
            setYAxisName(Object.keys(response?.scatter_plots)[1]);
          }
        }
      } catch (error) {
        console.error("Got an error: ", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedIndex, files]);

  /// Histogram

  const [selectedHistogramColumn, setSelectedHistogramColumn] = useState<
    string | null
  >(null);

  const handleHistogramSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;
    setSelectedHistogramColumn(newValue);
  };

  const [xAxisName, setXAxisName] = useState<string | null>(null);

  const handleXAxisSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;

    setXAxisName(newValue);
  };

  const [yAxisName, setYAxisName] = useState<string | null>(null);

  const handleYAxisSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;
    setYAxisName(newValue);
  };

  // Beta Version (KuberAPI Scatter Plot States)

  const [xAxisNameKuber, setXAxisNameKuber] = useState<string | null>(null);

  const handleXAxisKuberSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;

    setXAxisNameKuber(newValue);
  };

  const [yAxisNameKuber, setYAxisNameKuber] = useState<string | null>(null);

  const handleYAxisKuberSelection = (
    event: React.ChangeEvent<{}>,
    value: string | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const newValue = value ? value : null;
    setYAxisNameKuber(newValue);
  };

  const [xData, setXData] = useState<number[]>([]);
  const [yData, setYData] = useState<number[]>([]);
  const [selectedBoxViolinFeatures, setSelectedBoxViolinFeatures] = useState<
    string[]
  >([]);
  const [boxViolinData, setBoxViolinData] = useState<{
    [key: string]: number[];
  }>({});

  const handleBoxViolinFeaturesChange = (
    event: SelectChangeEvent<string[]>,
    child: ReactNode
  ) => {
    setSelectedBoxViolinFeatures(event.target.value as string[]);
  };

  useEffect(() => {
    const loadXAxis = async () => {
      const newXData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/" + xAxisName + ".json")
      );
      if (Array.isArray(newXData)) {
        setXData(newXData as number[]);
      }
    };
    const loadYAxis = async () => {
      const newYData = await readJsonFromS3(
        bucketName,
        files[selectedIndex].Key.replace(".json", "/" + yAxisName + ".json")
      );
      if (Array.isArray(newYData)) {
        setYData(newYData as number[]);
      }
    };
    if (files.length > 0 && xAxisName) {
      loadXAxis();
    }
    if (files.length > 0 && yAxisName) {
      loadYAxis();
    }
  }, [xAxisName, yAxisName]);

  useEffect(() => {
    const loadBoxData = async () => {
      var newDataMapping = {} as { [key: string]: number[] };
      // Iterate through the keys in boxViolinPlots
      console.log(selectedBoxViolinFeatures);
      for (const key in selectedBoxViolinFeatures) {
        console.log(selectedBoxViolinFeatures[key]);
        newDataMapping[selectedBoxViolinFeatures[key]] = await readJsonFromS3(
          bucketName,
          files[selectedIndex].Key.replace(
            ".json",
            "/" + selectedBoxViolinFeatures[key] + ".json"
          )
        );
      }
      // Update state once all promises are resolved
      console.log(newDataMapping);
      setBoxViolinData(newDataMapping);
    };

    loadBoxData();
    console.log(boxViolinData);
  }, [selectedBoxViolinFeatures]); // Depend on boxViolinPlots to refetch when it changes

  interface HistogramProps {
    histogram?: { [key: string]: HistogramData };
    key_name: string | null;
  }

  const Histogram: React.FC<HistogramProps> = ({ histogram, key_name }) => {
    if (!histogram) {
      return <div>Histogram Not Found!</div>;
    }
    if (!key_name) {
      return <div>Histogram Not Found!</div>;
    }
    // const { bin_edges, hist } = histogram?.[key];
    const bin_edges = histogram?.[key_name]?.["bin_edges"];
    const hist = histogram?.[key_name]?.["hist"];
    const cum_hist = histogram?.[key_name]?.["cum_hist"];

    // Prepare data for Plotly
    const trace = {
      x: bin_edges,
      y: hist,
      type: "bar" as const,
      marker: {
        color: "blue", // Change color as needed
      },
      // Configure the bars to be displayed as a histogram
      autobinx: false,
      histfunc: "sum" as const,
      histnorm: "density" as const,
      xbins: {
        start: Math.min(...bin_edges),
        end: Math.max(...bin_edges),
        size:
          (Math.max(...bin_edges) - Math.min(...bin_edges)) /
          (bin_edges.length - 1),
      },
    };
    const cum_trace = {
      x: bin_edges,
      y: cum_hist,
      type: "bar" as const,
      marker: {
        color: "red", // Change color as needed
      },
      // Configure the bars to be displayed as a histogram
      autobinx: false,
      histfunc: "sum" as const,
      histnorm: "density" as const,
      xbins: {
        start: Math.min(...bin_edges),
        end: Math.max(...bin_edges),
        size:
          (Math.max(...bin_edges) - Math.min(...bin_edges)) /
          (bin_edges.length - 1),
      },
    };

    const layout = {
      title: "Histogram",
      xaxis: {
        title: "Bin Edges",
      },
      yaxis: {
        title: "Frequency",
      },
      bargap: 0.05, // Adjust the gap between bars
    };
    const cum_layout = {
      title: "Cumulative Histogram",
      xaxis: {
        title: "Bin Edges",
      },
      yaxis: {
        title: "Cumulative Frequency",
      },
      bargap: 0.05, // Adjust the gap between bars
    };

    return (
      <div>
        <Typography>{key_name}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Plot
              data={[trace]}
              layout={layout}
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Plot
              data={[cum_trace]}
              layout={cum_layout}
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  /// Statistical Analysis

  interface StatProbs {
    statistical_analysis?: { [key: string]: StatisticalAnalysisProps };
  }

  const StatisticsTable: React.FC<StatProbs> = ({ statistical_analysis }) => {
    if (!statistical_analysis) {
      return <div>Statistical Analysis Not Found!</div>;
    }

    const headers = [
      "mean",
      "median",
      "minimum",
      "maximum",
      "std",
      "percentile_25",
      "percentile_50",
      "percentile_75",
      "count",
      "missing",
      "missing_percent",
    ];

    return (
      <TableContainer
        component={Paper}
        sx={{ maxWidth: 1000, margin: "auto", mt: 4 }}
      >
        <Table aria-label="statistics table">
          <TableHead>
            <TableRow>
              <TableCell>Variable</TableCell>
              {headers.map((header) => (
                <TableCell key={header} align="right">
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(statistical_analysis).map((key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {key}
                </TableCell>
                {headers.map((header) => (
                  <TableCell key={header} align="right">
                    {parseFloat(statistical_analysis[key][header]).toFixed(2)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  /// Feature Correlation

  interface FeatureCorrelationProps {
    feature_correlation?: { [key: string]: { [key: string]: number } };
  }

  const FeatureCorrelationMatrix: React.FC<FeatureCorrelationProps> = ({
    feature_correlation,
  }) => {
    if (!feature_correlation) {
      return <div>Feature Correlation Not Found!</div>;
    }

    // Extract labels and values for the heatmap
    const labels = Object.keys(feature_correlation);
    const values = labels.map((row) =>
      labels.map((col) => feature_correlation[row][col])
    );

    return (
      <Plot
        data={[
          {
            type: "heatmap",
            x: labels,
            y: labels,
            z: values,
            colorscale: "Viridis", // You can change this to any other color scale
          },
        ]}
        layout={{
          title: "Feature Correlation Matrix",
          xaxis: {
            title: "Features",
            automargin: true,
            scaleanchor: "y",
            scaleratio: 1,
          },
          yaxis: { title: "Features", automargin: true },
          height: 1000,
          width: 1000,
        }}
        style={{ width: "100%", height: "100%" }}
      />
    );
  };

  /// Feature Families

  interface DendrogramProps {
    feature_families?: FeatureFamilies;
  }

  const Dendrogram: React.FC<DendrogramProps> = ({ feature_families }) => {
    console.log(feature_families);
    if (
      !feature_families ||
      !feature_families.hierarchies ||
      !feature_families.indexes
    ) {
      return <div>Dendrogram Not Found!</div>;
    }

    const { hierarchies, indexes } = feature_families;

    // Process the hierarchical data for plotting
    const icoords = hierarchies.map((h) => [h[0], h[1], h[1], h[0]]);
    const dcoords = hierarchies.map((h) => [h[2], h[2], h[3], h[3]]);

    return (
      <Plot
        data={[
          {
            type: "scatter" as const,
            mode: "lines" as const,
            x: icoords.flat(),
            y: dcoords.flat(),
            line: { color: "blue", width: 2 },
          },
        ]}
        layout={{
          title: "Feature Families Dendrogram",
          xaxis: {
            title: "Index",
            tickvals: indexes.map((_, i) => i),
            ticktext: indexes,
          },
          yaxis: {
            title: "Distance",
          },
        }}
        style={{ width: "100%", height: "800px" }}
      />
    );
  };

  /// Scatter Plots

  interface ScatterPlotProps {
    scatter_plots?: { [key: string]: number[] | object };
    x_axis_name: string | null;
    y_axis_name: string | null;
    beta: bool;
  }
  const ScatterPlot: React.FC<ScatterPlotProps> = ({
    scatter_plots,
    x_axis_name,
    y_axis_name,
    beta,
  }) => {
    if (!x_axis_name || !y_axis_name) {
      return <div>Scatter Plot Not Found!</div>;
    }
    if (!scatter_plots || !scatter_plots["scatter_plots"]) {
      return <div>Invalid Structure for Scatter Plot Data!</div>;
    }
    if (!(typeof scatter_plots["scatter_plots"] === "object")) {
      return <div>Invalid Type for Scatter Plot Data!</div>;
    }
    if (!beta) {
      return (
        <Plot
          data={[
            {
              x: xData,
              y: yData,
              type: "scatter",
              mode: "markers",
              marker: { color: "blue" },
            },
          ]}
          layout={{
            title: `Scatter Plot of ${x_axis_name} vs ${y_axis_name}`,
            xaxis: {
              title: x_axis_name,
              // automargin: true,
              scaleanchor: "y",
              scaleratio: 1,
            },
            yaxis: {
              title: y_axis_name,
              automargin: true,
            },
          }}
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else {
      return (
        <div>
          <Plot
            data={[
              {
                x: Object.values(scatter_plots["scatter_plots"])[0],
                y: Object.values(scatter_plots["scatter_plots"])[1],
                type: "scatter",
                mode: "markers",
                marker: { color: "blue" },
              },
            ]}
            layout={{
              title: `Scatter Plot of ${x_axis_name} vs ${y_axis_name}`,
              xaxis: {
                title: x_axis_name,
                // automargin: true,
                scaleanchor: "y",
                scaleratio: 1,
              },
              yaxis: {
                title: y_axis_name,
                automargin: true,
              },
            }}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      );
    }
  };

  /// Input Preview

  const InputPreview: React.FC<InputPreviewProps> = ({ inputs_dict }) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Key</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(inputs_dict).map(([key, value]) => {
              if (
                key === "dataset_info" &&
                typeof value === "object" &&
                value !== null &&
                "path" in value
              ) {
                return (
                  <React.Fragment key={key}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Input File Name
                      </TableCell>
                      <TableCell>
                        {value.path.substring(value.path.lastIndexOf("/") + 1)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              } else if (
                (typeof value === "number" ||
                  typeof value === "string" ||
                  value === null) &&
                !(
                  key === "project_name" ||
                  key === "environment" ||
                  key === "structures_to_process" ||
                  key === "drillholes_to_process" ||
                  key === "litholens_save_path_type" ||
                  key === "feature_list" ||
                  key === "s3_data_save_path"
                )
              ) {
                return (
                  <TableRow
                    key={key}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell>
                      {value === null ? "null" : value.toString()}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  /// Box - Violin Plot

  interface BoxViolinPlotProps {
    boxViolinData: { [key: string]: number[] };
  }

  const BoxViolinPlot: React.FC<BoxViolinPlotProps> = ({ boxViolinData }) => {
    if (!boxViolinData) {
      return <div>Box-Violin Plots Not Found!</div>;
    }

    const data = Object.entries(boxViolinData).map(([key, value]) => {
      return {
        type: "violin" as const,
        y: value,
        name: key,
        box: {
          visible: true,
        },
        meanline: {
          visible: true,
        },
        // boxpoints: false,
      };
    });

    return (
      <div>
        {boxViolinData && (
          <Plot
            data={data}
            layout={{ title: "Box-Violin Plots of the Selected Features" }}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>
    );
  };

  /// Sparsity Matrix

  interface SparsityMatrixProps {
    data: SparsityData;
  }

  const SparsityMatrix: React.FC<SparsityMatrixProps> = ({ data }) => {
    if (!data) {
      return <div>Sparsity Matrix Not Found!</div>;
    }
    if (!Object.keys(data).includes("sparsity_matrix")) {
      return <div>Sparsity Matrix Not Found!</div>;
    }
    const sub_data = data.sparsity_matrix as SparsitySubData;
    // Generate traces for each key in the data object
    const traces = Object.keys(data.sparsity_matrix).map((key, index) => {
      const intervals = sub_data[key];

      // Determine visibility array based on the specified intervals
      const yValues = Array.from({ length: 100 }, (_, i) => i); // Example y-values
      const visibleYValues = yValues.map((y) => {
        // Check if y is within any of the specified intervals
        const isWithinInterval = intervals.some(
          ([min, max]) => y >= min && y <= max
        );
        return isWithinInterval ? NaN : y; // Set y as NaN to make it invisible within the intervals
      });

      return {
        x: Array(visibleYValues.length).fill(key), // X-axis has the key repeated for each y-value
        y: visibleYValues, // Y-values with NaN in interval ranges
        type: "bar",
        name: key,
        opacity: 1,
        marker: { color: "blue" },
        hoverinfo: "skip", // Optional: remove hover info for invisible parts
      };
    });

    return (
      <Box>
        <Plot
          data={traces as Data[]}
          layout={{
            title: "Sparsity Plot",
            // xaxis: { title: "Keys" },
            yaxis: { title: "Values" },
            showlegend: true,
            barmode: "overlay",
            width: 2000,
            height: 500,
          }}
          config={{ responsive: true }}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    );
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ maxHeight: 400, overflow: "auto" }}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              title="List of EDA Jobs"
            >
              {files.map((file, index) => (
                <ListItemButton
                  key={index} // Ensure unique key for each item
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={file.Key.replace(
                      `${pathPrefix}/responses/${serviceName}/`,
                      ""
                    )}
                  />
                </ListItemButton>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            id="input-preview"
            style={{ flex: "auto", textAlign: "center", margin: "20px" }}
          >
            <InputPreview
              inputs_dict={apiResponse?.inputs_dict || {}}
            ></InputPreview>
          </div>
        </Grid>
      </Grid>

      <div>
        {/* <Histogram
          histogram={apiResponse.histogram}
          key_name={selectedHistogramColumn}
        />
        <StatisticsTable
          statistical_analysis={apiResponse.statistical_analysis}
        /> */}
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={activeTab}
          onChange={handleChange}
          aria-label="file tabs"
          sx={{
            backgroundColor: "white", // Light teal background for the whole tabs bar
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Adding a subtle shadow under the tabs bar
            "& .MuiTabs-flexContainer": {
              gap: "10px", // Adds space between each tab/button
            },
          }}
        >
          <Tab
            key="histogram"
            label="Histogram"
            id="histogram"
            aria-controls={`tabpanel-histogram`}
          />
          <Tab
            key="statistical-analysis"
            label="Statistical Analysis"
            id="statistical-analysis"
            aria-controls={`tabpanel-statistical-analysis`}
          />
          <Tab
            key="feature-correlation"
            label="Feature Correlation"
            id="feature-correlation"
            aria-controls={`tabpanel-feature-correlation`}
          />
          <Tab
            key="scatter-plots"
            label="Scatter Plots"
            id="scatter-plots"
            aria-controls={`tabpanel-scatter-plots`}
          />
          <Tab
            key="box-violin"
            label="Box Violin Plots"
            id="box-violin"
            aria-controls={`tabpanel-box-violin`}
          />
          <Tab
            key="sparsity-matrix"
            label="Sparsity Matrix"
            id="sparsity-matrix"
            aria-controls={`tabpanel-sparsity-matrix`}
          />
          <Tab
            key="scatter-plots-beta"
            label="Scatter Plots (Beta)"
            id="scatter-plots-beta"
            aria-controls={`tabpanel-scatter-plots-beta`}
          />
        </Tabs>

        {isLoading ? (
          <div style={{ textAlign: "center", margin: "15px" }}>
            <CircularProgress />
          </div>
        ) : apiResponse?.status === "requested" ? (
          <div>
            <Alert severity="warning">Job in progress</Alert>
          </div>
        ) : apiResponse?.status === "failed" ? (
          <div>
            <Alert severity="error">Job failed!</Alert>
          </div>
        ) : (
          <div>
            <div
              hidden={activeTab !== 0}
              id="histogram"
              style={{ flex: "auto", textAlign: "center", margin: "20px" }}
            >
              <FormControl fullWidth margin="normal">
                <Autocomplete
                  options={
                    apiResponse?.histogram
                      ? Object.keys(apiResponse?.histogram)
                      : []
                  }
                  getOptionLabel={(option) => option}
                  value={selectedHistogramColumn}
                  onChange={handleHistogramSelection}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Histogram & Cumulative Histogram Column"
                      variant="outlined"
                    />
                  )}
                  fullWidth
                />
              </FormControl>
              <Histogram
                histogram={apiResponse.histogram}
                key_name={selectedHistogramColumn}
              />
            </div>

            <div
              hidden={activeTab !== 1}
              id="statistical-analysis"
              style={{ flex: "auto", textAlign: "center", margin: "20px" }}
            >
              <StatisticsTable
                statistical_analysis={apiResponse?.statistical_analysis}
              />
            </div>

            <div
              hidden={activeTab !== 2}
              id="feature-correlation"
              style={{ flex: "auto", textAlign: "center", margin: "20px" }}
            >
              <FeatureCorrelationMatrix
                feature_correlation={apiResponse?.feature_correlation}
              />
            </div>

            {/* <div
              hidden={activeTab !== 3}
              id="feature-families"
              style={{ flex: "auto", textAlign: "center", margin: "20px" }}
            >
              <Dendrogram feature_families={apiResponse.feature_families} />
            </div> */}
            <div
              hidden={activeTab !== 3}
              id="scatter-plots"
              style={{ flex: "auto", textAlign: "center", margin: "20px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      options={
                        apiResponse?.scatter_plots
                          ? Object.keys(apiResponse?.scatter_plots)
                          : []
                      }
                      getOptionLabel={(option) => option}
                      value={xAxisName}
                      onChange={handleXAxisSelection}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Scatter Plot: X Axis"
                          variant="outlined"
                        />
                      )}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      options={
                        apiResponse?.scatter_plots
                          ? Object.keys(apiResponse?.scatter_plots)
                          : []
                      }
                      getOptionLabel={(option) => option}
                      value={yAxisName}
                      onChange={handleYAxisSelection}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Scatter Plot: Y Axis"
                          variant="outlined"
                        />
                      )}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <ScatterPlot
                scatter_plots={apiResponse?.scatter_plots}
                x_axis_name={xAxisName}
                y_axis_name={yAxisName}
                beta={false}
              />
            </div>

            <div
              hidden={activeTab !== 4}
              id="violin-plots"
              style={{ flex: "auto", textAlign: "center", margin: "20px" }}
            >
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="mutiple-select-label">Select Keys</InputLabel>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  value={selectedBoxViolinFeatures}
                  onChange={handleBoxViolinFeaturesChange}
                  input={<OutlinedInput label="Select Keys" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {Object.keys(apiResponse?.statistical_analysis || {}).map(
                    (key) => (
                      <MenuItem key={key} value={key}>
                        <Checkbox
                          checked={selectedBoxViolinFeatures.indexOf(key) > -1}
                        />
                        <ListItemText primary={key} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <BoxViolinPlot boxViolinData={boxViolinData} />
            </div>

            <div
              hidden={activeTab !== 5}
              id="sparsity-matrix"
              style={{
                flex: "auto",
                textAlign: "center",
                margin: "20px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <Button
                variant="contained"
                onClick={(e) =>
                  handleKuberSubmit({
                    service: "talim_eda/sparsity_matrix",
                    request_body: apiResponse.inputs_dict,
                    // job_item: jobItem,
                    environment: env,
                  } as RunServiceRequest)
                }
                onError={log("errors")}
                disabled={runKuberServiceMutationResult.isLoading}
              >
                Get Sparsity Matrix
              </Button>
              {(runKuberServiceMutationResult?.isSuccess ||
                runKuberServiceMutationResult?.isError) &&
                typeof runKuberServiceMutationResult?.data === "object" &&
                Object.keys(runKuberServiceMutationResult?.data).includes(
                  "sparsity_matrix"
                ) && (
                  <SparsityMatrix
                    data={runKuberServiceMutationResult?.data as SparsityData}
                  />
                )}
            </div>

            <div
              hidden={activeTab !== 6}
              id="scatter-plots-beta"
              style={{
                flex: "auto",
                textAlign: "center",
                margin: "20px",
                overflowX: "auto",
                overflowY: "auto",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      options={
                        apiResponse?.scatter_plots
                          ? Object.keys(apiResponse?.scatter_plots)
                          : []
                      }
                      getOptionLabel={(option) => option}
                      value={xAxisNameKuber}
                      onChange={handleXAxisKuberSelection}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Scatter Plot: X Axis"
                          variant="outlined"
                        />
                      )}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <Autocomplete
                      options={
                        apiResponse?.scatter_plots
                          ? Object.keys(apiResponse?.scatter_plots)
                          : []
                      }
                      getOptionLabel={(option) => option}
                      value={yAxisNameKuber}
                      onChange={handleYAxisKuberSelection}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Scatter Plot: Y Axis"
                          variant="outlined"
                        />
                      )}
                      fullWidth
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                onClick={(e) =>
                  handleKuberSubmit({
                    service: "talim_eda/scatter_plot_2d",
                    request_body: {
                      ...apiResponse.inputs_dict,
                      x: xAxisNameKuber,
                      y: yAxisNameKuber,
                    },
                    // job_item: jobItem,
                    environment: env,
                  } as RunServiceRequest)
                }
                onError={log("errors")}
                disabled={runKuberServiceMutationResult.isLoading}
              >
                Get Scatter Plots
              </Button>
              {(runKuberServiceMutationResult?.isSuccess ||
                runKuberServiceMutationResult?.isError) &&
                typeof runKuberServiceMutationResult?.data === "object" &&
                Object.keys(runKuberServiceMutationResult?.data).includes(
                  "scatter_plots"
                ) && (
                  <div>
                    {/* <Typography>
                      {JSON.stringify(
                        Object.keys(runKuberServiceMutationResult?.data)
                      )}
                    </Typography> */}
                    <ScatterPlot
                      scatter_plots={
                        runKuberServiceMutationResult?.data as {
                          [key: string]: number[] | object;
                        }
                      }
                      x_axis_name={xAxisNameKuber}
                      y_axis_name={yAxisNameKuber}
                      beta={true}
                    />
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EDAResponseViewer;
