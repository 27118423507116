import AWS from "aws-sdk";
import { parse } from "papaparse";
import { Buffer } from "buffer";
import archiver from "archiver";
import fs from "fs";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Auth } from "aws-amplify";
import config, { ENVIRONMENT } from "../../config";

// ------------------------------------------------------------------------------------
interface UploadProgress {
  [key: string]: number;
}

// ------------------------------------------------------------------------------------
export interface S3File {
  Key: string;
  Size: number;
  Type: string;
}

type AWSCredentials = {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
};

type SignInResult = {
  user: any;
  credentials: AWSCredentials;
};

async function signInAndConfigureAWS(
  username: string,
  password: string
): Promise<SignInResult> {
  try {
    const user = await Auth.signIn(username, password);
    const credentials = (await Auth.currentCredentials()) as AWSCredentials;
    localStorage.setItem("AWSCreds", JSON.stringify(credentials));
    return { user, credentials };
  } catch (error) {
    throw error;
  }
}

const AWSCreds = localStorage.getItem("AWSCreds");
if (AWSCreds) {
  const parsedCreds = JSON.parse(AWSCreds);
  AWS.config.update({
    credentials: Auth.essentialCredentials(parsedCreds),
  });
}

const s3 = new AWS.S3();

// ------------------------------------------------------------------------------------
export const bytesToMB = (bytes: number): number => {
  return parseFloat((bytes / 1024 / 1024).toFixed(3));
};

// ------------------------------------------------------------------------------------
interface FileInfo {
  baseName: string;
  fileName: string;
  extension: string;
  pathPrefix: string;
}

export const getFileInfo = (fileKey: string): FileInfo => {
  const parts = fileKey.split("/");
  const baseName = parts.pop() ?? "";
  const extensionMatch = baseName.match(/(\.\w+)$/);
  const extension = extensionMatch ? extensionMatch[1] : "";
  const fileName = baseName.replace(extension, "");
  const pathPrefix = parts.join("/");

  return {
    baseName,
    fileName,
    extension,
    pathPrefix,
  };
};

// ------------------------------------------------------------------------------------
export const uploadFilesToS3 = async (
  files: File[],
  setUploadProgress: React.Dispatch<React.SetStateAction<UploadProgress>>,
  bucketName: string,
  pathPrefix: string
) => {
  const uploadPromises = files.map((file) => {
    const params = {
      Bucket: bucketName,
      Key: `${pathPrefix}/${file.name}`,
      Body: file,
    };

    return new AWS.S3.ManagedUpload({
      service: s3,
      params: params,
    })
      .on("httpUploadProgress", (progress: AWS.S3.ManagedUpload.Progress) => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: (progress.loaded / progress.total) * 100,
        }));
      })
      .promise();
  });

  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    throw new Error("Upload failed: " + error);
  }
};

// ------------------------------------------------------------------------------------
export const uploadFolderToS3 = async (
  folderFiles: File[],
  setUploadProgress: React.Dispatch<React.SetStateAction<UploadProgress>>,
  bucketName: string,
  pathPrefix: string
) => {
  // Ensure no trailing slash in pathPrefix
  const sanitizedPathPrefix = pathPrefix.endsWith("/")
    ? pathPrefix.slice(0, -1)
    : pathPrefix;

  const uploadPromises = folderFiles.map((file) => {
    // Construct the key that mimics the folder structure on S3
    const relativePath = (file as any).webkitRelativePath || file.name; // webkitRelativePath provides the folder structure
    const s3Key = `${sanitizedPathPrefix}/${relativePath}`;

    const params = {
      Bucket: bucketName,
      Key: s3Key,
      Body: file,
    };

    return new AWS.S3.ManagedUpload({
      service: s3,
      params: params,
    })
      .on("httpUploadProgress", (progress: AWS.S3.ManagedUpload.Progress) => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [relativePath]: (progress.loaded / progress.total) * 100,
        }));
      })
      .promise()
      .catch((error: unknown) => {
        // Handle the error explicitly as an instance of the Error class
        if (error instanceof Error) {
          console.error(`Failed to upload ${relativePath}:`, error.message);
          return { file: relativePath, error: error.message };
        } else {
          // For non-standard errors, fall back to a generic message
          console.error(`Failed to upload ${relativePath}:`, String(error));
          return { file: relativePath, error: "An unknown error occurred" };
        }
      });
  });

  try {
    const uploadResults = await Promise.all(uploadPromises);
    const failedUploads = uploadResults.filter((result) => result?.error);

    if (failedUploads.length > 0) {
      console.warn("Some files in the folder failed to upload:", failedUploads);
    }

    return uploadResults;
  } catch (error) {
    // Type the error here as well
    if (error instanceof Error) {
      throw new Error(
        "Folder upload process encountered a critical failure: " + error.message
      );
    } else {
      throw new Error("Folder upload process encountered an unknown failure");
    }
  }
};

// ------------------------------------------------------------------------------------
export const uploadDatasetToS3 = async (
  datasetFiles: File[],
  setUploadProgress: React.Dispatch<React.SetStateAction<UploadProgress>>,
  bucketName: string,
  pathPrefix: string
) => {
  // Ensure no trailing slash in pathPrefix
  const sanitizedPathPrefix = pathPrefix.endsWith("/")
    ? pathPrefix.slice(0, -1)
    : pathPrefix;

  // Extract top-level folders by filtering the first part of webkitRelativePath
  const topLevelFolders = Array.from(
    new Set(
      datasetFiles
        .map((file) => (file as any).webkitRelativePath.split("/")[1]) // Extract the first subfolder inside the main folder
        .filter((folder) => folder) // Filter out any undefined or empty entries
    )
  );

  // Validate that we have exactly two folders: "train" and "validation"
  const requiredFolders = ["train", "validation"];
  const extraFolders = topLevelFolders.filter(
    (folder) => !requiredFolders.includes(folder)
  );
  const missingFolders = requiredFolders.filter(
    (folder) => !topLevelFolders.includes(folder)
  );

  if (extraFolders.length > 0 || missingFolders.length > 0) {
    const errorMessage = `Invalid dataset structure. ${
      missingFolders.length > 0
        ? `Missing folders: ${missingFolders.join(", ")}. `
        : ""
    }${
      extraFolders.length > 0
        ? `Extra folders: ${extraFolders.join(", ")}.`
        : ""
    }`;
    throw new Error(errorMessage);
  }

  // Proceed with the file upload if the folder structure is correct
  const uploadPromises = datasetFiles.map((file) => {
    const relativePath = (file as any).webkitRelativePath;
    const s3Key = `${sanitizedPathPrefix}/${relativePath}`;

    const params = {
      Bucket: bucketName,
      Key: s3Key,
      Body: file,
    };

    return new AWS.S3.ManagedUpload({
      service: s3,
      params: params,
    })
      .on("httpUploadProgress", (progress: AWS.S3.ManagedUpload.Progress) => {
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [relativePath]: (progress.loaded / progress.total) * 100,
        }));
      })
      .promise()
      .catch((error: unknown) => {
        if (error instanceof Error) {
          console.error(`Failed to upload ${relativePath}:`, error.message);
          return { file: relativePath, error: error.message };
        } else {
          console.error(`Failed to upload ${relativePath}:`, String(error));
          return { file: relativePath, error: "An unknown error occurred" };
        }
      });
  });

  try {
    const uploadResults = await Promise.all(uploadPromises);
    const failedUploads = uploadResults.filter((result) => result?.error);

    if (failedUploads.length > 0) {
      console.warn(
        "Some files in the dataset failed to upload:",
        failedUploads
      );
    }

    return uploadResults;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(
        "Dataset upload process encountered a critical failure: " +
          error.message
      );
    } else {
      throw new Error("Dataset upload process encountered an unknown failure");
    }
  }
};

// ------------------------------------------------------------------------------------
export const fetchFiles = async (
  bucketName: string,
  pathPrefix: string,
  filterExtensions: string[] = []
): Promise<S3File[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix,
  };
  try {
    const data = await s3.listObjectsV2(params).promise();
    let files =
      data.Contents?.map((file) => ({
        Key: file.Key || "",
        Size: bytesToMB(file.Size || 0),
        Type: file.Key ? file.Key.split(".").pop() || "unknown" : "unknown",
      })) || [];

    // Filter files based on the extension types provided in filterExtensions
    if (filterExtensions.length > 0) {
      files = files.filter((file) => filterExtensions.includes(file.Type));
    }

    return files;
  } catch (error) {
    console.error("Error fetching files: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const fetchFolders = async (
  bucketName: string,
  pathPrefix: string
): Promise<{ Key: string }[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix.endsWith("/") ? pathPrefix : `${pathPrefix}/`, // Ensure folder key ends with '/'
    Delimiter: "/", // This ensures that only top-level folders are returned
  };

  try {
    const data = await s3.listObjectsV2(params).promise();

    // Extract only folder prefixes (which represent folders)
    const folders = data.CommonPrefixes
      ? data.CommonPrefixes.map((prefix) => ({
          Key: prefix.Prefix || "",
        }))
      : [];

    return folders;
  } catch (error: unknown) {
    console.error("Error fetching folders: ", error);
    throw new Error("Failed to fetch folders from S3.");
  }
};

// ------------------------------------------------------------------------------------
export const fetchImmediateFiles = async (
  bucketName: string,
  pathPrefix: string,
  filterExtensions: string[] = []
): Promise<S3File[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix,
    Delimiter: "/", // Use delimiter to get only immediate level files
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    let files =
      data.Contents?.map((file) => ({
        Key: file.Key || "",
        Size: bytesToMB(file.Size || 0),
        Type: file.Key ? file.Key.split(".").pop() || "unknown" : "unknown",
      })) || [];

    // Filter files based on the extension types provided in filterExtensions
    if (filterExtensions.length > 0) {
      files = files.filter((file) => filterExtensions.includes(file.Type));
    }

    // Exclude folders by checking if the key ends with a slash
    files = files.filter((file) => !file.Key.endsWith("/"));

    return files;
  } catch (error) {
    console.error("Error fetching files: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const deleteFile = async (
  bucketName: string,
  key: string
): Promise<void> => {
  const params = { Bucket: bucketName, Key: key };
  try {
    await s3.deleteObject(params).promise();
  } catch (error) {
    console.error("Error deleting file: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const deleteFolders = async (
  bucketName: string,
  folderKey: string
): Promise<void> => {
  const params = {
    Bucket: bucketName,
    Prefix: folderKey.endsWith("/") ? folderKey : `${folderKey}/`, // Ensure folderKey ends with a '/'
  };

  try {
    // Step 1: List all objects under the folder
    const listedObjects = await s3.listObjectsV2(params).promise();

    if (!listedObjects.Contents || listedObjects.Contents.length === 0) {
      console.log(`No objects found in folder: ${folderKey}`);
      return; // If there are no objects, there's nothing to delete
    }

    // Step 2: Delete all objects under the folder
    const deleteParams = {
      Bucket: bucketName,
      Delete: {
        Objects: listedObjects.Contents.map((obj) => ({ Key: obj.Key! })),
        Quiet: false, // Optional: Set to true if you don't want a verbose response
      },
    };

    await s3.deleteObjects(deleteParams).promise();
    console.log(`Folder deleted successfully: ${folderKey}`);
  } catch (error) {
    console.error(`Error deleting folder ${folderKey}:`, error);
    throw new Error(
      `Failed to delete folder ${folderKey} in bucket ${bucketName}`
    );
  }
};

// ------------------------------------------------------------------------------------
export const createSegmentationDatasetFolder = async (
  bucketName: string,
  pathPrefix: string,
  folderName: string
): Promise<void> => {
  const suffixes: string[] = [
    "/",
    "/train/",
    "/validation/",
    "/train/images/",
    "/train/labels/",
    "/validation/images/",
    "/validation/labels/",
  ];
  for (const suffix of suffixes) {
    try {
      const params = {
        Bucket: bucketName,
        Key: `${pathPrefix}/${folderName}${suffix}`, // Append '/' to indicate it's a folder
      };
      await s3.putObject(params).promise(); // Creating an empty object to represent the folder
      console.log(`Folder '${folderName}' created successfully.`);
    } catch (error) {
      console.error("Error creating folder:", error);
      throw new Error(
        `Failed to create folder '${folderName}' in bucket '${bucketName}'.`
      );
    }
  }
};

// ------------------------------------------------------------------------------------
export const getDownloadUrl = async (
  bucketName: string,
  key: string
): Promise<string> => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Expires: 300, // URL expires in 60 seconds
  };
  try {
    const url = await s3.getSignedUrlPromise("getObject", params);
    return url;
  } catch (error) {
    console.error("Error generating download URL: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const writeJsonToS3 = async (
  bucketName: string,
  key: string,
  jsonData: object
): Promise<void> => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: JSON.stringify(jsonData),
    ContentType: "application/json",
  };

  try {
    await s3.putObject(params).promise();
    console.log("JSON file uploaded successfully.");
  } catch (error) {
    console.error("Error uploading JSON file: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const writeImageToS3 = async (
  bucketName: string,
  key: string,
  bodyString: string,
  bodyType: string
): Promise<void> => {
  const params = {
    Bucket: bucketName,
    Key: key,
    Body: Buffer.from(bodyString, "base64"),
    ContentType: bodyType,
  };

  try {
    await s3.putObject(params).promise();
    console.log("Image uploaded successfully.");
  } catch (error) {
    console.error("Error uploading image: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const readJsonFromS3 = async (
  bucketName: string,
  key: string
): Promise<any> => {
  const params = {
    Bucket: bucketName,
    Key: key,
  };

  try {
    const data = await s3.getObject(params).promise();
    if (data.Body) {
      return JSON.parse(data.Body.toString());
    } else {
      throw new Error("No data found or data is inaccessible.");
    }
  } catch (error) {
    console.error("Error reading JSON file: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const getTableDataFromCsv = async (
  bucketName: string,
  key: string,
  numRows: number = 100
): Promise<{ columns: string[]; data: any[] }> => {
  try {
    const url = await getDownloadUrl(bucketName, key);
    let totalRows = 0;
    let position = 0;
    const chunkSize = 1024 * 20; // Start with 10KB;
    let rows: any[] = [];

    while (totalRows < 5) {
      const headers = new Headers();
      headers.append("Range", `bytes=${position}-${position + chunkSize - 1}`);
      const response = await fetch(url, { headers });
      const csvText = await response.text();
      const partialData = parse(csvText, { header: totalRows === 0 });
      rows.push(...partialData.data);
      totalRows = rows.length;
      if (partialData.data.length > 0) {
        position += chunkSize;
      } else {
        break; // Break if we read a chunk that contains no data
      }
    }

    if (rows.length > 0) {
      const tableData = rows.slice(0, numRows);
      const tableHeaderColumns = Object.keys(rows[0]);
      return { columns: tableHeaderColumns, data: tableData };
    }
    throw new Error("No data found in the CSV.");
  } catch (error) {
    console.error("Failed to download or parse CSV:", error);
    throw error; // Re-throw error to be handled by the caller
  }
};

// ------------------------------------------------------------------------------------
export const listFolders = async (
  bucketName: string,
  pathPrefix: string
): Promise<string[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix + "/",
    Delimiter: "/", // Use delimiter to group objects by folder
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const folders =
      data.CommonPrefixes?.map((prefix) => prefix.Prefix || "") || [];
    return folders;
  } catch (error) {
    console.error("Error listing folders: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const listFolder = async (
  bucketName: string,
  pathPrefix: string
): Promise<string[]> => {
  const params = {
    Bucket: bucketName,
    Prefix: pathPrefix + "/",
    Delimiter: "/", // Use delimiter to group objects by folder
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const files = data.Contents?.map((prefix) => prefix.Key || "") || [];
    return files;
  } catch (error) {
    console.error("Error listing files: ", error);
    throw error;
  }
};

// ------------------------------------------------------------------------------------
export const downloadS3FolderAsZip = async (bucket: string, prefix: string) => {
  try {
    const listParams = {
      Bucket: bucket,
      Prefix: prefix,
    };

    const listedObjects = await s3.listObjectsV2(listParams).promise();

    if (!listedObjects.Contents || listedObjects.Contents.length === 0) {
      console.log("No files found in the specified folder.");
      return;
    }

    const zip = new JSZip();

    const filePromises = listedObjects.Contents.map(async (file) => {
      if (file.Key) {
        const data = await s3
          .getObject({
            Bucket: bucket,
            Key: file.Key,
          })
          .promise();

        if (data.Body) {
          zip.file(file.Key.replace(prefix, ""), data.Body as Blob);
        }
      }
    });

    await Promise.all(filePromises);

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `${prefix.replace(/\//g, "_")}.zip`);
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
